<template>
  <div class="ant-hx-table">
    <!--搜索条件 -->
    <div class="ant-hx-table-search">
      <a-form :model="searchForm" layout="inline">
        <a-form-item label="">
            <a-cascader style="width:200px" v-model:value="searchForm.startAraay" :options="$store.state.app.cityDict"
                        :changeOnSelect="true" @change="setStartAddress" :show-search="{ filter }" placeholder="选择起始地"/>
            <a-cascader style="width:200px" v-model:value="searchForm.endAraay" :options="$store.state.app.cityDict"
                        :changeOnSelect="true" @change="setEndAddress" :show-search="{ filter }" placeholder="选择目的地"/>
        </a-form-item>
        <a-form-item label="">
          <a-select allowClear ref="select" v-model:value="searchForm.editStatus" placeholder="线路修改状态" style="width: 140px">
            <a-select-option :value="1">是</a-select-option>
            <a-select-option :value="0">否</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-space>
            <a-button class="searchLoading-button" :loading="loading" type="primary" @click="onSearch"
                      :disabled="loading">
              查询
            </a-button>
            <a-button @click="reset">
              重置
            </a-button>
            <a-button @click="batchShow=true , batchForm={}">
              批量设置
            </a-button>
            <a-popconfirm :loading="startRoutePlanLoading"   :disabled="startRoutePlanLoading"  title="是否系统更新所有线路" ok-text="是" cancel-text="否" @confirm="onStartRoutePlan">
              <a-button :loading="startRoutePlanLoading"   :disabled="startRoutePlanLoading">
                规划路线
              </a-button>
            </a-popconfirm>
          </a-space>
        </a-form-item>

      </a-form>
    </div>
    <a-card :bodyStyle="{'padding':'0px'}" ref="card">
      <div class="paginationCss">
        <a-pagination :disabled="loading" v-model:current="pagination.current" :total="pagination.total"
                      show-quick-jumper show-size-changer :page-size="pagination.pageSize"
                      :show-total="pagination.showTotal" @change="handleTableChange" @showSizeChange="handleTableChange"
                      state.pagination.pageSize=pag.pageSize>
          <template #buildOptionText="props">
            <span v-if="props.value !== '50'">{{ props.value }}条/页</span>
            <span v-else>全部</span>
          </template>
        </a-pagination>
      </div>
      <div v-if="listData.length>0&&loading===false">
        <div class="a-card-table" v-for="item in listData" :key="item.index">
          <div class="a-card-table-row">
            <a-row >
              <a-col :span="4">线路名称：<span class="col-head">{{ item.startEndCity }}</span></a-col>
              <a-col :span="20">线路方案：<span class="col-head">{{ item.lineName }} </span></a-col>
              <a-col :span="4" class="col-set">承运方：{{ item.carrierName }}</a-col>
              <a-col :span="3" class="col-set">报价：￥{{ item.cost }}</a-col>
              <a-col :span="2" class="col-set">时效：{{ item.prescription }}</a-col>
              <a-col :span="3" class="col-set">途径地：{{ item.passPoint }}</a-col>
              <a-col :span="3" class="col-set">承运次数：{{ item.num }}</a-col>
              <a-col :span="5" class="col-set ">备注：
                <span class="col-remark">{{ item.remark }}</span>
              </a-col>
            </a-row>
            <div v-if="item.noLineList!=null">
              <h1 class="nullDataH1">当前无可发运线路，系统建议路线 <div class="waypoint"  @click="onWaypointShow(item)" >自定义规划线路</div></h1>
              <div class="noPlanList" :class="itemDetailed.state===1?'planList':''" v-for="(itemDetailed , index) in item.noLineList" :key="index">
                <a-row >
                  <a-col :span="24">
                    步骤{{index+1}}
                  </a-col>
                  <a-col :span="24">
                    线路名: {{itemDetailed.lineName}}
                  </a-col>
                  <a-col :span="24">
                    承运商:   {{itemDetailed.carrierName}}
                  </a-col>
                  <a-col :span="6">
                    成本：  {{itemDetailed.price}}
                  </a-col>
                  <a-col :span="6">
                    时效： {{itemDetailed.prescription}}
                  </a-col>
                  <a-col :span="6">
                    公里： {{itemDetailed.km}}
                  </a-col>
                  <a-col :span="24">
                    <div style="width: 577px; word-break: break-all;">
                      备注：  <span :class="itemDetailed.state===0?'noRemark':''">{{itemDetailed.remark}}</span>
                    </div>
                  </a-col>
                </a-row>
              </div>
            </div>
          </div>
          <div class="a-card-table-form" v-if="item.noLineList==null">
            <a-row>
              <a-col :span="3">成本报价设置</a-col>
              <a-col :span="2" class="d">价格：
                <a-select class="set-show-input" size="small" v-model:value="item.floatType" :disabled="item.disabled"
                          :options="$store.state.enumAll.LinePriceType" placeholder=""/>
              </a-col>
              <a-col :span="3" class="d">
                <a-input class="set-show-input set-show-input-value" size="small" @change="onFloatPriceChange(item)"
                         :disabled="item.disabled" v-model:value="item.floatPrice"/>
                元
              </a-col>
              <a-col :span="7" class="d">
                <div>提示语：</div>
                <a-input class="set-show-input set-show-input-remark" size="small" v-model:value="item.hint"
                         :disabled="item.disabled" :maxlength="16"/>
              </a-col>
              <a-col :span="3">状态：
                <a-switch :disabled="item.disabled" v-model:checked="item.state"/>
              </a-col>
              <a-col :span="3">永久不变：
                <a-switch :disabled="item.disabled" :checkedValue="1" :unCheckedValue="0" v-model:checked="item.isChange"/>
              </a-col>

              <a-col class="show" :span="2">
                <a-button v-if="item.disabled" class="editButton" size="small" @click="item.disabled=!item.disabled">
                  修改
                </a-button>
                <a-button v-if="!item.disabled" type="primary" size="small" :loading="disabledLoading"
                          @click="onSubmit(item)">
                  确认提交
                </a-button>
              </a-col>
            </a-row>
          </div>
          <div class="a-card-table-show" v-if="item.noLineList==null">
            <a-row>
              <a-col :span="2">更多线路</a-col>
              <a-col :span="18"  class="waypoint"  @click="onWaypointShow(item)" >自定义规划线路</a-col>
              <a-col :span="4" class="show" @click="more(item)">{{ item.show === true ? '展开>>>' : '收起<<<' }}</a-col>
            </a-row>
          </div>
          <div class="a-card-table-line" v-if="!item.show">
            <div class="a-card-table-row bColoerF5" v-for="(obj,i) in item.children" :key="i"
                 @click="onShowSetLine(item,obj)">
              <div class="col-table">
                <a-row>
                  <a-col :span="4">{{ obj.lineName }}</a-col>
                  <a-col :span="6" class="d">总报价{{ obj.cost }}
                    （
                    <div v-for="(c,cid) in obj.lineDetail" :key="cid">
                      <span v-if="cid!==0">-</span> {{ c.cost }}
                    </div>
                    ）
                  </a-col>
                  <a-col :span="2">时效{{ obj.prescription }}</a-col>
                  <a-col :span="2">公里{{ obj.km }}</a-col>
                  <a-col :span="7">承运商：{{ obj.carrierName }}</a-col>
                  <a-col :span="3" v-if="item.recLineId === obj.id">
                    <div class="flex">
                      <div class="m-r3" style="color:#E9941B">
                        <span v-if="obj.isChange === 0">自动更新</span>
                        <span v-if="obj.isChange === 1">手动更新</span>
                      </div> 
                      <div class="col-table-tag">已选</div></div>
                  </a-col>

                </a-row>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="a-card-table">
        <div class="none" v-if="loading">
          <a-button :loading="loading">数据加载中</a-button>
        </div>
        <div class="none" v-else>暂无数据</div>
      </div>

    </a-card>

    <a-modal v-model:visible="batchShow" title="批量设置" width="600px" :confirm-loading="batchLoading" @ok="onBatchEdit"
             okText="确认提交">
      <a-form :model="batchForm" ref="batchFormFormRef" layout="vertical" :rules="rules">
        <a-form-item label="起始地">
          <div class="setStartAddress">
            <a-cascader style="width:47%" v-model:value="batchForm.startAraay" :options="$store.state.app.cityDict"
                        :changeOnSelect="true" @change="batchStartAddress" :show-search="{ filter }"
                        placeholder="选择起始地"/>
            <div class="">—</div>
            <a-cascader style="width:47%" v-model:value="batchForm.endAraay" :options="$store.state.app.cityDict"
                        :changeOnSelect="true" @change="batchEndAddress" :show-search="{ filter }" placeholder="选择目的地"/>
          </div>

        </a-form-item>
        <!--        <a-form-item label="城市等级">-->
        <!--          <div class="setStartAddress">-->
        <!--            <a-select style="width:40%" class="set-show-input" v-model:value="batchForm.startCityLevel"-->
        <!--                      :options="cityLevelType" placeholder=""/>-->
        <!--            <div class="">—</div>-->
        <!--            <a-select style="width:40%" class="set-show-input" v-model:value="batchForm.endCityLevel"-->
        <!--                      :options="cityLevelType" placeholder=""/>-->
        <!--            <a-button value="small" type="primary" @click="onSaveLevel">-->
        <!--              保存-->
        <!--            </a-button>-->
        <!--          </div>-->
        <!--          <div class="startEndLevels">-->
        <!--            <template v-for="(tag, index) in batchForm.startEndLevels" :key="tag">-->
        <!--              <a-tag closable @close="handleClose(tag)">-->
        <!--                {{ tag.startCityLevelName }}-{{ tag.endCityLevelName }}-->
        <!--              </a-tag>-->
        <!--            </template>-->
        <!--          </div>-->
        <!--        </a-form-item>-->
        <a-form-item label="浮动类型" required name="floatType">
          <a-select v-model:value="batchForm.floatType" :options="$store.state.enumAll.LinePriceType" placeholder=""/>
        </a-form-item>
        <a-form-item label="浮动价格" required name="floatPrice">
          <a-input v-model:value="batchForm.floatPrice" type="number" suffix="元"/>
        </a-form-item>
        <!--        <a-form-item label="推广成本" required name="promoCost">-->
        <!--          <a-input v-model:value="batchForm.promoCost" type="number" suffix="元"/>-->
        <!--        </a-form-item>-->
        <!--        <a-form-item label="活动成本" required name="activityCost">-->
        <!--          <a-input v-model:value="batchForm.activityCost" type="number" suffix="元"/>-->
        <!--        </a-form-item>-->
        <a-form-item  name="hint" >
          <template #label  name="hint">   <a-space> 提示语    <a-switch v-model:checked="batchForm.hintState"/></a-space></template>
          <a-textarea v-if="batchForm.hintState" v-model:value="batchForm.hint" :auto-size="{ minRows: 2, maxRows: 5 }"/>
        </a-form-item>
        <a-form-item  >
          <template #label> <a-space> 状态  <a-switch v-model:checked="batchForm.stateState"/></a-space></template>
          <a-radio-group  v-if="batchForm.stateState"  v-model:value="batchForm.state">
            <a-radio :value="1">是</a-radio>
            <a-radio :value="0">否</a-radio>
          </a-radio-group>
        </a-form-item>

      </a-form>
    </a-modal>
    <a-modal v-model:visible="waypointShow" title="自定义规划线路" width="600px" :confirm-loading="waypointLoading" @ok="onAddWaypoint"
             okText="确认提交">
      <a-form :model="waypointForm" >
        <a-form-item label="规划线路">
          <a-cascader style="width:80%;margin-right: 20px" v-model:value="waypointForm.startAraay" :options="$store.state.app.cityDict"
                      :changeOnSelect="true" @change="onCityWaypoint" :show-search="{ filter }"
                      placeholder="选择城市"/>
          <a-button type="primary"  size="size"  @click="onStagingWaypoint()">保存</a-button>
          <div class="startEndLevels">
            <template v-for="(tag, index) in waypointForm.cityTag" :key="tag">
              <a-tag closable @close="onDeleteWaypoint(tag)">
                {{ tag.provinceName }}-{{ tag.cityName }}
              </a-tag>
            </template>
          </div>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { onMounted, reactive, ref, toRefs } from 'vue'
import { page, list, editLineManage, batchEditTransLineManage, startRoutePlan, customCircuits } from '@/api/carrier/optimalLine'
import { message } from 'ant-design-vue'

export default {
  setup () {
    const batchFormFormRef = ref()
    const state = reactive({
      loading: true,
      waypointShow: false,
      waypointLoading: false,
      waypointForm: {
        cityTag: []
      },
      startRoutePlanLoading: false,
      batchLoading: false,
      disabledLoading: false,
      listData: [],
      searchForm: {
        editStatus: null,
        startAddress: {},
        endAddress: {}
      },
      batchShow: false,
      batchForm: {
        startEndLevels: []
      },
      pagination: {
        total: null,
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      cityLevelType: [
        {
          label: '一级城市',
          value: 1
        },
        {
          label: '二级城市',
          value: 2
        },
        {
          label: '三级城市',
          value: 3
        },
        {
          label: '四级城市',
          value: 4
        },
        {
          label: '五级城市',
          value: 5
        }
      ],
      rules: {
        floatType: {
          required: true,
          message: '请选择浮动类型'
        },
        startEndLevels: {
          required: true,
          message: '请选择城市等级'
        },
        startAraay: {
          required: true,
          message: '请填写起始地'
        },
        floatPrice: {
          required: true,
          message: '请填写公司利润'
        },
        promoCost: {
          required: true,
          message: '请填写推广成本'
        },
        activityCost: {
          required: true,
          message: '请选择活动成本'
        }
      }
    })
    const onSearch = () => {
      state.pagination.current = 1
      loadData()
    }
    const loadData = () => {
      state.loading = true
      page({
        startCityId: state.searchForm.startCityId,
        startProvinceId: state.searchForm.startProvinceId,
        startAreaId: state.searchForm.startAreaId,
        endCityId: state.searchForm.endCityId,
        endProvinceId: state.searchForm.endProvinceId,
        endAreaId: state.searchForm.endAreaId,
        updateStatus: state.searchForm.editStatus,
        endType: 1,
        current: state.pagination.current,
        size: state.pagination.pageSize,
        totalSize: state.pagination.total
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          if (state.listData !== null) {
            state.listData.forEach(e => {
              e.disabled = true
              e.show = true
            })
          }
          state.pagination.total = res.data.total
          state.pagination.current = res.data.current
        }
      }).catch(err => {
        console.log('分页错误   ' + err)
      })
        .finally(() => {
          state.loading = false
        })
    }
    // 点击更多
    const more = (e) => {
      if (e.show) {
        list({ cityRelId: e.cityRelId }).then(res => {
          if (res.code === 10000) {
            e.children = res.data
          }
        })
      }
      e.show = !e.show
    }

    const handleTableChange = (pag, filters) => {
      state.pagination.current = pag
      state.pagination.pageSize = filters
      loadData()
    }
    // 重置
    const reset = () => {
      state.searchForm = {
        startAddress: {},
        endAddress: {}
      }
      state.pagination.total = null
      loadData()
    }

    onMounted(() => {
      loadData()
    })
    const setStartAddress = (value, selectedOptions) => {
      state.searchForm.startProvinceId = value[0]
      if (value.length > 1) {
        state.searchForm.startCityId = value[1]
      } else {
        state.searchForm.startCityId = ''
      }
      if (value.length > 2) {
        state.searchForm.startAreaId = value[2]
      } else {
        state.searchForm.startAreaId = null
      }
    }

    const setEndAddress = (value, selectedOptions) => {
      state.searchForm.endProvinceId = value[0]
      if (value.length > 1) {
        state.searchForm.endCityId = value[1]
      } else {
        state.searchForm.endCityId = ''
      }
      if (value.length > 2) {
        state.searchForm.endAreaId = value[2]
      } else {
        state.searchForm.endAreaId = null
      }
    }

    // 模糊搜索
    const filter = (inputValue, path) => {
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    }
    // 确认提交
    const onSubmit = (e) => {
      state.disabledLoading = true
      if (e.state) {
        e.state = 1
      } else {
        e.state = 0
      }
      editLineManage({
        ...e,
        endType: 1
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          loadData()
        }
      }).finally(() => {
        state.disabledLoading = false
      })
      if (e.state === 1) {
        e.state = true
      } else {
        e.state = false
      }
    }
    // 选择配置的线路
    const onShowSetLine = (e, i) => {
      e.lineName = i.lineName
      e.carrierName = i.carrierName
      e.cost = i.cost
      e.prescription = i.prescription
      e.passPoint = i.passPoint
      e.num = i.num
      e.remark = i.remark
      e.recLineId = i.id
      e.isChange = i.isChange
    }
    const onFloatPriceChange = (e) => {
      if (Number(e.floatPrice) < 0) {
        e.floatPrice = ''
      }
    }

    // 批量修改
    const onBatchEdit = () => {
      batchFormFormRef.value.validate()
        .then(() => {
          if (((state.batchForm.startAraay.length <= 0 || state.batchForm.startAraay === undefined) && (state.batchForm.endAraay.length <= 0 || state.batchForm.endAraay === undefined)) && (state.batchForm.startEndLevels.length <= 0 || state.batchForm.startEndLevels === undefined)) {
            message.error('城市等级与起始地必须选择一个上传')
            return
          }
          if (state.batchForm.hintState === false || state.batchForm.hintState === undefined) {
            state.batchForm.hint = null
          }
          if (state.batchForm.hintState === true && (state.batchForm.hint === null || state.batchForm.hint === '' || state.batchForm.hint === undefined)) {
            message.error('提示语必须填写')
            return
          }
          if (state.batchForm.stateState === false || state.batchForm.stateState === undefined) {
            state.batchForm.state = null
          }
          state.batchLoading = true
          batchEditTransLineManage(state.batchForm).then(res => {
            if (res.code === 10000) {
              message.success(res.msg)
              state.batchShow = false
              state.batchForm = {}
              loadData()
            }
          }).finally(() => {
            state.batchLoading = false
          })
        })
    }
    const batchStartAddress = (value, selectedOptions) => {
      state.batchForm.startProvinceId = value[0]
      if (value.length > 1) {
        state.batchForm.startCityId = value[1]
      } else {
        state.batchForm.startCityId = null
      }
    }
    const batchEndAddress = (value, selectedOptions) => {
      state.batchForm.endProvinceId = value[0]
      if (value.length > 1) {
        state.batchForm.endCityId = value[1]
      } else {
        state.batchForm.endCityId = null
      }
    }
    // 保存城市等级
    const onSaveLevel = () => {
      if (state.batchForm.startCityLevel === null || state.batchForm.startCityLevel === undefined) {
        message.error('必须选择起始城市等级')
        return
      }
      if (state.batchForm.endCityLevel === null || state.batchForm.endCityLevel === undefined) {
        message.error('必须选择目的城市等级')
        return
      }
      if (state.batchForm.startEndLevels === null || state.batchForm.startEndLevels === undefined) {
        state.batchForm.startEndLevels = []
      }
      let startEndLevelsSum = 0
      state.batchForm.startEndLevels.forEach(e => {
        if (e.name === (state.batchForm.startCityLevel + '-' + state.batchForm.endCityLevel)) {
          startEndLevelsSum++
        }
      })
      if (startEndLevelsSum > 0) {
        message.error('你选的城市等级已有重复的')
        return
      }
      state.batchForm.startEndLevels.push({
        name: state.batchForm.startCityLevel + '-' + state.batchForm.endCityLevel,
        startCityLevel: state.batchForm.startCityLevel,
        endCityLevel: state.batchForm.endCityLevel,
        startCityLevelName: state.cityLevelType[state.batchForm.startCityLevel - 1].label,
        endCityLevelName: state.cityLevelType[state.batchForm.endCityLevel - 1].label
      })
      state.batchForm.startCityLevel = null
      state.batchForm.endCityLevel = null
    }
    // 删除城市等级
    const handleClose = removedTag => {
      const tags = state.batchForm.startEndLevels.filter(tag => tag !== removedTag)
      console.log(tags)
      state.batchForm.startEndLevels = tags
    }
    // 规划路线新-思路 升级
    const onStartRoutePlan = () => {
      state.startRoutePlanLoading = true
      startRoutePlan({ }).then(res => {
        if (res.code === 10000) {
          message.success('预计10分钟左右，系统逐步更新完成')
        }
      }).finally(() => {
        state.startRoutePlanLoading = false
      })
    }
    // 展示途径点
    const onWaypointShow = (e) => {
      state.waypointShow = true
      state.waypointForm = e
    }
    // 添加途径点
    const onAddWaypoint = (e) => {
      // onWaypointShow
      const cityIds = []
      if (state.waypointForm.cityTag !== undefined) {
        state.waypointForm.cityTag.forEach(e => {
          cityIds.push(e.cityId)
        })
      }
      state.waypointLoading = true
      customCircuits({
        cityRelId: state.waypointForm.cityRelId,
        cityIds: cityIds
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.waypointForm = {
            cityTag: []
          }
          state.waypointShow = false
          loadData()
        }
      }).catch(err => {
        console.log(err)
      })
        .finally(() => {
          state.waypointLoading = false
        })
    }

    // 保存途径点
    const onStagingWaypoint = () => {
      if (state.waypointForm.cityId === null || state.waypointForm.cityId === undefined) {
        message.error('必须选择城市')
        return
      }
      if (state.waypointForm.startEndCity.indexOf(state.waypointForm.cityName) !== -1) {
        message.error('不能选择起始地')
        return
      }
      if (state.waypointForm.cityTag === null || state.waypointForm.cityTag === undefined) {
        state.waypointForm.cityTag = []
      }
      if (state.waypointForm.cityTag.length > 3) {
        message.error('最多4个城市')
        return
      }
      let cityTagSum = 0
      state.waypointForm.cityTag.forEach(e => {
        if (e.cityId === state.waypointForm.cityId) {
          cityTagSum++
        }
      })
      if (cityTagSum > 0) {
        message.error('你选的城市已有重复的')
        return
      }
      state.waypointForm.cityTag.push({
        cityName: state.waypointForm.cityName,
        cityId: state.waypointForm.cityId,
        provinceId: state.waypointForm.provinceId,
        provinceName: state.waypointForm.provinceName
      })
      state.waypointForm.startAraay = null
      state.waypointForm.cityId = null
      state.waypointForm.cityName = null
      state.waypointForm.provinceId = null
      state.waypointForm.provinceName = null
    }
    const onCityWaypoint = (value, selectedOptions) => {
      state.waypointForm.provinceId = value[0]
      state.waypointForm.provinceName = selectedOptions[0].label
      if (value.length === 2) {
        state.waypointForm.cityId = value[1]
        state.waypointForm.cityName = selectedOptions[1].label
      } else if (value.length === 3) {
        state.waypointForm.cityId = value[2]
        state.waypointForm.cityName = selectedOptions[2].label
      } else {
        state.waypointForm.cityId = null
        state.waypointForm.cityName = null
      }
    }
    // 删除暂存途径点
    const onDeleteWaypoint = removedTag => {
      const tags = state.waypointForm.cityTag.filter(tag => tag !== removedTag)
      state.waypointForm.cityTag = tags
    }
    return {
      ...toRefs(state),
      reset,
      onSearch,
      loadData,
      handleTableChange,
      setStartAddress,
      setEndAddress,
      filter,
      onSubmit,
      onShowSetLine,
      more,
      onFloatPriceChange,
      onBatchEdit,
      batchEndAddress,
      batchStartAddress,
      batchFormFormRef,
      onSaveLevel,
      onStartRoutePlan,
      onAddWaypoint,
      onStagingWaypoint,
      onCityWaypoint,
      onWaypointShow,
      onDeleteWaypoint,
      handleClose
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
@import '../TableTool.less';
</style>
