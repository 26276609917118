import { postBodyRequest , getRequest } from '@/utils/axios'
// 路线规划列表（展开）
export function page (params) {
  return postBodyRequest('/carrier/city/line/carrierLineManage/List', params)
}
// 最优路线管理列表
export function list (params) {
  return getRequest('/carrier/city/line/carrierRecLine/List', params)
}
// 路线规划（查询按钮）
export function carrierRecLine (params) {
  return getRequest('/carrier/city/line/carrierRecLine', params)
}

// 设置最优路线管理
export function editLineManage (params) {
  return postBodyRequest('/carrier/city/line/editLineManage', params)
}
// 批量设置最优路线管理B端
export function batchEditLineManage (params) {
  return postBodyRequest('/carrier/city/line/batchEditLineManage', params)
}
// 批量设置最优路线管理C端
export function batchCEndLineManage (params) {
  return postBodyRequest('/carrier/city/line/batchCEndLineManage', params)
}

// 批量设置最优路线管理运力
export function batchEditTransLineManage (params) {
  return postBodyRequest('/carrier/city/line/batchEditTransLineManage', params)
}
// 规划路线新-思路 升级
export function startRoutePlan (params) {
  return postBodyRequest('/carrier/carrier/line/start-route-plan-max', params)
}
// 自定义线路
export function customCircuits (params) {
  return postBodyRequest('/carrier/carrier/line/custom-circuits', params)
}
